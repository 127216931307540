
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  display: flex;
}

.active {
  color: #8dd7f1;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  transition: all 0.4s;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  transition: all 0.4s;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  transition: all 0.4s;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3e5c7d;
  transition: all 0.4s;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.gwDiyc {
  flex-direction: column;
}
/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  color: #4a4a4a;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  background: #f6f7fb;
  overflow-x: hidden;

  .settings {
    padding: 40px;
    margin: 40px;
    background: white;
    min-height: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .dial {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    div {
      width: 22%;
      margin: 9px 12px;
      background: white;
      height: 56px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      transition: all 0.4s;
      &:hover {
        background: #ccc;
        cursor: pointer;
      }
    }
  }

  .company_detail {
    p {
      margin-bottom: 10px !important;
    }

  }

  .audioBlock {
    display: flex;
    margin-top: 51px;
    flex-direction: column;

    audio {
      margin-top: 20px;
    }

    span {
      font-size: 20px;
    }
  }

  .callImgBlock {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
    margin-right: 40px;

    img {
      width: 100%;
      border-radius: 100%;
      object-fit: cover;
    }
  }

  .call_action {
    padding: 4px 50px;
    border: 1px solid #3da4ff;
    margin-right: 10px;
    background: white;
  }

  .call_btn {
    padding: 20px;
    border: none;
    background: #9aff9a;
    border-radius: 100%;
    margin-top: 30px;
    color: white;
  }

  .callScreenOutBack {
    position: absolute;
    width: 100%;
    background: rgba(0, 0, 0, 0.53);
    top: 0;
    left: 0;
    height: 100vh;
    scroll-behavior: unset;
    bottom: 0;
    right: 0;
    z-index: 6000;
  }

  .company_create {
    border: none;
    padding: 10px;
    color: white;
    background: #3da4ff;
  }

  .react-datetime-picker__wrapper {
    align-items: center;
  }

  .menuLink {
    height: 60px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-right: 1px solid #74c5e2;

    a {
      color: #8cbbcd
    }

    a.active {
      color: #74c5e2
    }
  }
  .triangle{
    width: 0;
    height: 0;
    position: fixed;
    border-style: solid;
    border-width: 18px 13px 0 13px;
    border-color: #adc9ee transparent transparent transparent;
    bottom: 82px;
    right: 49px;
  }
  .wrapper_call{
    position: fixed;
    bottom: 28px;
    right: 35px;
    width: 50px;
    height: 50px;
    background: #00ccff;
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
    transition: all 0.4s;

    &:hover{
      background: #6e89c1;

    }
  }

  .verifyBlock{
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000000b5;
    z-index: 700;
    top: 0;
    left: 0;
    .verifyChild{
      padding: 20px;
      background: white;
      height: 240px;
      width: 475px;
      position: fixed;
      margin: auto;
      top: 0;
      display: flex;
      flex-direction: column;
      right: 0;
      align-items: center;
      bottom: 0;
      left: 0;
      justify-content: space-between;
      div{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      span{
        cursor: pointer;
      }
      label{
        display: flex;
        align-items: center;
        justify-content: center;
        input{
          border-bottom: 1px solid black;
          transition: all 0.4s;
          &:focus{
            border-bottom: 1px solid #6db0d5;
          }
        }
      }
    }
  }

  .navProfile {
    width: 490px;
    height: 60px;
    background: #ffffff;
    position: absolute;
    top: 0;
    left: 80px;
    z-index: 6;
    display: flex;
    flex-direction: row;
    transition: all 0.4s;

    a {
      color: #2a2a2a;
      width: 75px;
    }

    &.show {
      left: -440px;

    }
  }

  .mainTask {
    padding: 23px;
    overflow: auto;
    display: flex;
    flex-direction: row;

    & > div {
      margin: 0 20px;

      div {
        min-height: 50px;
      }
    }

    div {
      width: 235px;
      text-align: center;
      border-bottom: 2px solid #cccccc;
      display: flex;
      flex-direction: column;

      .count {
        color: #cccccc;
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;
      background: white;
      border: 1px solid #cccccc;
      padding: 0 10px;
      margin: 5px 0;
    }

    .today {
      border-bottom: 2px solid #74c5e2;
    }

    .old {
      border-bottom: 2px solid red;

    }

    span.old {
      color: #c31e39;
      border-bottom: none;

    }

    span.today {
      color: #74c5e2;
      border-bottom: none;

    }
  }

  .taskLineEdit {
    position: fixed;
    bottom: 0;
    width: inherit;
    background: white;
    height: 100px;

    ul {
      padding: 0 !important;
      list-style: none;
      display: flex;
      justify-content: center;

      li {
        padding: 37px 90px;
        border-top: 3px solid gray;
        margin: 0 5px;
      }

      li.delete {
        padding: 37px 90px;
        border-top: 3px solid red;
      }
    }
  }

  .taskTable {
    padding: 30px;
    overflow: auto;

    .history_filter{
      overflow: hidden;
      display: flex;
      align-items: flex-end;
      background: white;
      font-size: 15px;
    }

    table, th {
      border: 1px solid #cccccc;
      background: white;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      tbody {
        tr {
          transition: 0.4s;

          &:hover {
            background: #e8e8e8;
          }
        }
      }


      .paginationBlock {
        ul {
          list-style: none;
          display: flex;
          flex-direction: row;
          margin: 0 !important;
          justify-content: flex-end;
        }
      }
    }

    th, td {
      height: 40px;
      padding: 1px 19px;
      font-size: 11px;
      min-width: 130px;
    }

    .taskAction {
      span {
        padding: 10px 20px;
        border-right: 1px solid #ccc;
        cursor: pointer;

        span {
          margin: 0 5px;
          border-right: none

        }
      }
    }
  }
}

.taskBtn {
  border: none;
  padding: 10px;
  margin: 0 25px;
  color: white;
  background: #74c5e2;
  width: 190px;
  transition: all 0.4s;

  &:hover {
    background: #3e7f95;
  }

  &.disable {
    color: white;
    background: #cccccc;
  }

  &.disable:hover {
    background: #cccccc;
  }
}

.modalCreateAndUpdate {
  span {
    color: #727272;
    font-size: 14px;
    border: none;
    width: 0;
    min-width: fit-content;
  }

  .react-datetime-picker{
    width: 100%;
  }
  label {
    margin: 10px 0;
    input {
      margin: 10px;
      width: 91%;
      border: none;
      outline: none;
      border-bottom: solid 1px black;
      transition: all 0.4s;
    }

    select {
      outline: none;
      height: 40px;
      width: 86%;
      margin-left: 8px;
    }
    textarea{
      width: 91%;
    }
  }

  input::placeholder,
  textarea::placeholder {
    font-size: 12px;
  }

  textarea {
    height: 28px;
  }
}

.process_single{
  min-width: 120px;
}

.process_border{
  border-bottom: 1px solid;
  padding-bottom: 10px;
}

.content_header {
  height: 220px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  input {
    font-size: 19px;
    border-bottom: none;
  }

  textarea {
    font-size: 15px;
    border-bottom: none;
    background: transparent;
    width: 100%;
    resize: none;
  }

  p {
    margin: 10px !important;
    display: flex;

    span {
    }

    select {
      border: none;
      background: transparent;
      outline: none;

    }
  }

}

.content_body {
  height: calc(100% - 220px);
  background: white;
  border-left: 2px solid #f6f7fb
}

.taskFilter {
  display: flex;
  background: #f6f7fb;
  align-items: center;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
  justify-content: space-between;

  .dayFilter {
    padding: 17px;
    width: 200px;
    display: flex;
    justify-content: space-around;
    border-right: 1px solid #74c5e2;

    span {
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        color: #cccccc;
      }
    }
  }

  select {
    height: 45px;
    margin-left: 20px;
    border: none;
    background: transparent;
    outline: none;
  }


}

.submitBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-top: 29px;

  span {
    padding: 10px;
    background: #80dbef;
    color: white;
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      background: #639ab1;
    }
  }
}

#task {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.task {
  background: white;
  padding: 20px;
}

#task td, #task th {
  border: 1px solid #ddd;
  padding: 8px;
}


#task tr:nth-child(even) {
  background-color: #f2f2f2;
}

#task tr:hover {
  background-color: #ddd;
}

#task th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  //background-color: #04AA6D;
  color: white;
}

.callFromWrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: #3f3f3fad;
}

.sideBarMenuUl .barMenuLi:hover,
.sideBarMenuUl .barMenuLi.active {
  z-index: 555555;
}

.loginContainer {
  position: absolute;
  box-shadow: 0 12px 30px rgba(80, 143, 244, .1);
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  background: white;
  height: 300px;
  bottom: 0;
  width: 483px;
  padding: 20px;

  .adminLoginForm {
    height: 260px;
    position: relative;
  }

  .adminLoginPage {
    height: 260px;
  }

  .adminLoginHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .adminLoginMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  #adminPass {
    text-align: center;
  }

  #adminPass::placeholder {
    text-align: center;
  }


  .adminInput {


    input {
      //width: 155px;
      border: none;
      outline: none;
      align-items: center;
      //border: none;
      border-bottom: 1px solid;
      border-radius: 10px;
      padding: 0 10px;
      width: 245px;
      transition: all 0.4s;
      text-align: center;

      &:focus {
        border-bottom: 1px solid #c0e9f7
      }

      &::placeholder {
        text-align: center;
      }

      //&:focus
    }
  }

  .adminFormLabel {
    align-items: center;
    border: none;
    border-bottom: 1px solid;
    border-radius: 10px;
    margin: 17px 0 40px;
    padding: 0 10px;
    width: 245px;
    transition: all 0.4s;

    &:focus-within {
      border-bottom: 1px solid #c0e9f7

    }
  }

  #adminPass {
    border: none;
    outline: none;
    width: 195px;
  }

  .adminLoginBtn {
    border: none;
    padding: 5px 30px;
    background: #97e7ff;
    color: white;
    font-size: 20px;
    border-radius: 4px;
    transition: all 0.4s;

    &:hover {
      box-shadow: 0 12px 30px rgba(80, 143, 244, .1);
      background: #4d98ac;

    }
  }
}

p {
  margin: 0;
}


.adminHeader {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.adminRow {
  min-width: calc(100% - 81px);
  width: 100%;
  height: fit-content;
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.searchLabel {
  width: 40px;
  height: 40px;
  border: 1px solid;
  display: inline-flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}

.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
}

.input-search {
  height: 30px;
  width: 30px;
  border-style: none;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color: #8dd7f1;
  color: transparent;
  padding: 10px;
}

.input-search::placeholder {
  color: transparent;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
  padding: 10px 0;
}

.btn-search {
  width: 30px;
  height: 30px;
  border-style: none;
  font-size: 15px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #ffffff;
  background-color: transparent;
  pointer-events: painted;
}

.btn_Yes,
.btn_no {
  width: 70px;
  border: none;
  color: white;
  background: red;
  border-radius: 4px;
  margin: 0 35px;
}

.btn_no {
  background: #8dd7f1;
}

.btn-search:focus ~ .input-search {
  width: 300px;
  border-radius: 0;
  background: #8dd7f1;
  border-bottom: 1px solid #8dd7f1;
  color: white;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

.input-search:focus {
  width: 300px;
  color: white;
  border-radius: 0;
  background: #8dd7f1;
  border-bottom: 1px solid #8dd7f1;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

.btn-search:focus ~ .input-search::placeholder {
  color: #fff;
}

.headerProfileImg {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.adminSideBar {
  min-height: 100vh;
  max-height: 999999px;
  height: auto;
  width: 80px;
  background: #fff;
  transition: all 0.4s;
  box-shadow: 0 12px 30px rgba(80, 143, 244, .1);

  .nameBlock {
    display: flex;
    flex-direction: column;
    font-size: 15px;
  }

  & > .sideBarMenuUl .barMenuLi {
    width: 100%;
    height: 65px
  }
}

.logo {
  color: #ccc;
}

.text {
  margin-top: 10px;
  transition: all 0.4s;
}

.menuBarIcon {
  right: 28px
}

.sideBarMenuUl {
  list-style: none;
  color: #96cdd4d4;
  padding: 0;

  a {
    text-decoration: none;
    color: rgba(1, 144, 168, 0.83);

    span {
      font-size: 10px;
    }
  }

  .barMenuLi {
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: center;
    width: 100%;
    transition: all 0.4s;
    padding: 5px 10px;


    &:hover,
    &.active {
      //background: #bdc0d9;
      //width: 215px;
      color: rgba(158, 238, 255, 0.83);
      border-radius: 10px;

      span {
        color: rgba(158, 238, 255, 0.83);

      }
    }

    //  .content {
    //    display: none;
    //  }
    //
    //  .custom_tooltip {
    //    height: auto;
    //    top: 0;
    //    flex-direction: column;
    //    /*background: #8dd7f1;*/
    //    color: #8dd7f1;
    //    opacity: 1;
    //    pointer-events: auto;
    //
    //    a {
    //      color: #8dd7f1;
    //      pointer-events: auto;
    //      width: 100%;
    //    }
    //
    //    .content {
    //      display: block;
    //    }
    //  }
    //}

    .custom_tooltip {
      height: 45px;
      padding: 10px 0;
      width: calc(100% - 80px);
      background: transparent;
      position: absolute;
      left: 80px;
      display: flex;
      opacity: 0;
      pointer-events: none;
      align-items: center;
      justify-content: center;
      transition: all 0.4s;
      border-radius: 10px;

      a {
        pointer-events: none;
      }
    }

    a {
      margin-left: 10px;
      text-decoration: none;
      color: #8dd7f1;
      transition: all 0.4s;
      pointer-events: none;

      &:hover ~ span {
        background: white;
        color: black;
        border-radius: 10px;
      }
    }
  }
}

.adminSideBar.show {
  & ~ .adminRow {
    min-width: calc(100% - 335px)
  }

  .sideBarMenuUl {
    .barMenuLi {
      width: 100%;
      transition: all 0.4s;
      padding: 10px;

      &:hover,
      &.active {
        background: #f6f7fb;
        color: #96cdd4d4;
        border-radius: 10px;

        span {
          color: #96cdd4d4;
        }

        .content {
          display: block;
        }
      }

      .custom_tooltip {
        display: none;
      }

      a {
        margin-left: 10px;
        text-decoration: none;
        color: #8dd7f1;
        transition: all 0.4s;
        pointer-events: auto;

      }
    }
  }

}
.dWLNfR{
  flex-direction: column;
}
.sc-dkrFOg.dmajdp{
  flex-direction: column;
}

.logo.textShow ~ .menuBarIcon {
  right: 27px;
}

.show {
  width: 335px;
}

.textShow {
  opacity: 0;
}

.profilePopUp {
  list-style: none;
  width: 100%;
  padding: 0;
  font-size: 18px;
  margin: 0;

  .popLi {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    /* margin: 0 28px; */
    padding: 12px;
    text-transform: capitalize;
    transition: all 3.4s;
    a {
      color: black;
      margin-left: 10px;
      text-decoration: none;
      transition: all 0.4s;
    }


    .popLiDiv {
      display: flex;
      flex-direction: column;
      font-size: 11px;
    }

    .message {
      color: #cccccc;
    }

    &:hover {

      a {
        color: white;
      }
    }

    span {
      margin-left: 10px;
    }
  }
}

.content {
  background: #f6f7fb;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.showAcc {
  height: auto;
  max-height: 99999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.settings__list {
  width: 100%;
  margin-top: 30px;

  select {
    height: 40px;
    margin: 12px 33px;
    width: 237px;
    border: 1px solid #616161;
  }

  .setting__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #cdf3ff;
    border-radius: 100%;
  }
}

.css-advny6-MuiPopper-root {
  top: 20px !important;
  left: -53px !important;
  height: 600px;
  overflow: hidden !important;
  overflow-y: auto !important;
}

.language__down {
  left: 0 !important;
  z-index: 2147483647;
}

.profile__down {
  left: -15px !important;
}
.dialScreen,
.callScreen {
  height: 400px;
  position: absolute;
  right: 0;
  bottom: 0;
  input{
    background: transparent;
  }
  .dial{
    div{
      width: 20%;
      height: 40px;
    }
  }
  .call_btn{
    padding: 10px;
  }

  .PhoneInputCountrySelect {
    display: none;
  }

  .call-animation {
    background: #fff;
    width: 135px;
    height: 135px;
    position: relative;
    margin: 0 auto;
    border-radius: 100%;
    border: solid 5px #fff;
    animation: play 2s ease infinite;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    //-ms-backface-visibility: hidden;
    backface-visibility: hidden;

  }

  .img {
    width: 135px;
    height: 135px;
    border-radius: 100%;
    position: absolute;
    left: -4px;
    top: -4px;
  }

  @keyframes play {

    0% {
      transform: scale(1);
    }
    15% {
      box-shadow: 0 0 0 5px rgba(32, 69, 134, 0.4);
    }
    25% {
      box-shadow: 0 0 0 10px rgba(54, 141, 199, 0.21), 0 0 0 20px rgba(87, 187, 227, 0.2);
    }
    25% {
      box-shadow: 0 0 0 15px rgba(92, 144, 255, 0.4), 0 0 0 30px rgba(71, 160, 238, 0.27);
    }

  }

  .callBTN {
    display: inline-block;
    background: #22d522;
    color: white;
    padding: 10px;
    border-radius: 45px;
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      background: green;
    }

    &.red {
      background: red;

      &:hover {
        background: #c31e39;
      }
    }
  }


  div {
    div {
      .phone_input {
        width: 200px;

        input {
          border: none;
          text-align: center;
          background: transparent;
        }
      }
    }
  }
}
.dialScreen{
  height: 400px;
  position: fixed;
  right: 24px;
  bottom: -46px;
}
.callScreen{
  top: 0;
  left: 0;
  margin: auto;
  width: 300px;
  background: white;
}
.dropTheme {
  background: #fff;
  color: #4a4a4a;

  .callHeader {
    height: 42px;
    padding: 10px;
    background: #5cb8ff;
    color: white;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }


  .history_input {
    border-bottom: 1px solid #cccccc;
    transition: all 0.4s;
    width: 88%;
    margin-bottom: 40px;

    &:focus {
      border-bottom: 1px solid #80d6ef;
    }
  }

  .startAndEnd {
    border-bottom: 1px solid #cccccc;
    transition: all 0.4s;
    width: 88%;
    margin-bottom: 40px;

    &:focus {
      border-bottom: 1px solid #80d6ef;
    }
  }

  .history_icon {
    &:hover {
      box-shadow: 0 12px 30px rgba(80, 143, 244, .1);
    }
  }

  .history_select {
    height: 40px;
    outline: none;
    margin-left: 10px;
    width: 88%;
  }

  .historyList {
    height: 48px;
    display: flex;
    padding: 3px;
    border-bottom: 1px solid #ccc;

    .call_icon {
      width: 40px;
      height: 40px;
      border: 1px solid;
      border-radius: 100%;
      display: inline-flex;
      margin: 0;
      justify-content: center;
      align-items: center;
    }

    .call_info {
      display: inline-flex;
      flex-direction: column;
      margin: 0 20px;
    }
  }

  .callNumberBlock {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    justify-content: flex-end;

    .numberItem {
      cursor: pointer;
      margin: 15px 26px;
      background: #ddf1f7;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      transition: all 0.4s;

      &:hover {
        background: #89a4af;
      }
    }

  }

  .notification {
    display: flex;
    height: 40px;
    align-items: center;
    padding: 20px;
    background: blueviolet;
    border-radius: 5px 5px 0 0;
  }
}

.icon__count {
  top: -11px;
  right: -3px;
  padding: 3px;
  background: #8dd7f1;
  border-radius: 10px;
  font-size: 10px;
}

.adminMain {
  width: 100%;
  //z-index: -10000;
  padding: 25px;

  .back {
    background: #00000073;
    width: 100%;
    height: 100vh;
    z-index: 5;
  }

  input {
    transition: all 0.4s;
    background: transparent;
  }

  .profileBlock {
    margin: 0;
    width: 100%;
    padding: 40px;
    background: white;
    box-shadow: 0 12px 30px rgba(80, 143, 244, .1);

    .color {
      color: black
    }

    .profileLeft {
      .profilePictureBlock {
        margin: 0;
        height: 225px;
        width: 225px;
        background: aliceblue;
        position: relative;

        label {
          height: 100%;
          min-width: 100%;
          margin: 0;
        }

        .blockImg {
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
          object-position: center;
          top: 0;
        }

        .close_add {
          position: absolute;
          background: blue;
          width: 25px;
          height: 25px;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          top: -10px;
          right: -11px;
          z-index: 12;
          cursor: pointer;
        }
      }

      input {
        border: none;
        outline: none;
        color: #b0b0b0;
        transition: all 0.4s;
      }

      .city_country {
        font-size: 22px;
        font-weight: 600;
        color: #435564;
      }

      .address {
        margin: 10px 0;
        color: #b0b0b0;

        &.color > input {
          color: black
        }
      }

      .role {
        display: flex;
        flex-direction: column;
      }
    }

    .profileRight {
      .editBlock {
        color: rgb(119, 119, 119);
        margin-left: 10px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        min-width: 145px;
        overflow-wrap: break-word;
        word-break: break-all;
      }

      .edit {
        padding: 0 10px;
        margin-left: 10px;
        outline: none;
        border: none;
        height: fit-content;
        color: white;
        background: #317eff;
      }

      .name_loc {
        display: flex;

        &.color input {
          color: black
        }

        & input {
          min-width: 100px;
          width: 160px;
          margin: 0 5px;
        }
      }

      .role {
        display: inline-block;
        margin: 10px 0;
        color: #5bbeda;
      }

      .contact {
        min-width: 200px;
        display: inline-block;
        width: fit-content;
      }

      .info > div {
        margin: 20px 0;

        &.color input {
          color: black;
        }
      }
    }

    input {
      border: none;
      outline: none;
      color: #b0b0b0;
    }

    .headline {
      margin: 21px 0;
      color: #bbbbbb;
      display: flex;
      align-items: center;
      font-weight: bold;

      .line {
        height: 2px;
        width: 100%;
        background: #efefef;
        margin: 0 10px;
      }
    }
  }
}

.callBlock,
.lineChart {
  color: white;
  height: 340px;
  border-radius: 40px;
  display: flex;
  box-shadow: rgba(80, 143, 244, 0.1) 0 12px 30px;
  align-items: center;
  justify-content: center;
  padding: 11px;
  margin: 5px 5px;
  width: 47%;
}

.descLabel {
  margin: 25px 10px;
}

.headerBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.callBlockTime {
  background: white;
  height: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  box-shadow: rgba(80, 143, 244, 0.1) 0 12px 30px;
  margin: 5px 5px;
  width: 47%;

  .button {
    padding: 4px 30px;
    border: none;
    color: white;
    box-shadow: rgba(80, 143, 244, 0.1) 0 12px 30px;
  }
}

.deleteTitle {
  font-size: 26px;
  color: #8dd7f1;
  display: flex;
  justify-content: center;
}

.deleteBtnBlock {
  display: flex;
  justify-content: center;
  height: 40px;
  margin: 24px;
}

.search {
  input {
    margin: 10px;
    border: none;
    outline: none;
    border-bottom: solid 1px black;
    transition: all 0.4s;

    &:focus {
      border-color: #8dd7f1;
    }
  }
}

div {
  div {
    label {
      margin: 10px;
    }

    input, textarea {
      //margin: 10px;
      border: none;
      outline: none;
      border-bottom: solid 1px black;
      transition: all 0.4s;

      &.taskInput {
        width: 293px;
        margin: 10px 0;
      }

      &:focus {
        border-color: #8dd7f1;
      }
    }
  }

  label {
    margin: 10px 0;

    span {
      margin: 0 10px;
      color: black;
      min-width: 145px;
      border-bottom: 1px solid #35cfed;
    }

    input {
      outline: none;
      width: 100%;
    }

    input::placeholder {
      color: #848484;
    }
  }
}

.createHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 13px 0;
}

.imageLabel {
  background: #35cfed;
  padding: 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    box-shadow: rgba(59, 113, 210, 0.25) 0 12px 30px;
  }
}

.dragBlockDiv {
  height: 500px;
  cursor: grab;
  //margin: 5px;
  box-shadow: rgba(59, 113, 210, 0.25) 0 12px 30px;
  width: 33%;
  min-width: 200px;
  background: white;
  border-radius: 10px;
}


.loaderBack {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fffdfdbf;
  top: 0;
  left: 0;
  z-index: 54554;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createBtn {
  border: none;
  padding: 3px 32px;;
  margin: 10px;
  text-align: center;
  color: white;
  background: #35cfed;
  border-radius: 5px;
  transition: all 0.4s;
  cursor: pointer;
  &:hover {
    background: #146d80;
    box-shadow: rgba(59, 113, 210, 0.25) 0 12px 30px;
  }
}


body.dark {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    transition: all 0.4s;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    transition: all 0.4s;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    transition: all 0.4s;

  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #3e5c7d;
    transition: all 0.4s;

  }

  .callBlockTime {
    background: #2A3F54;

    div {
      color: white;

      div {
        font-size: 30px;
      }
    }
  }

  background: #2A3F54;

  .adminRow {
    color: white;
  }

  .adminSideBar {
    background: #000;
    min-height: 100vh;
    max-height: 999999px;
    height: auto;

    .sideBarMenuUl .barMenuLi:hover,
    .sideBarMenuUl .barMenuLi.active {
      background: #5e626f;
      width: 215px;
      color: rgba(150, 205, 212, 0.831372549);
      border-radius: 10px;
      z-index: 55555;

      .custom_tooltip {
        background: #5e626f;
      }

      .content {
        background: #5e626f;
      }
    }
  }

  .popLi {
    color: white;

    a {
      color: white;
      text-decoration: none;
    }
  }

  .dropTheme {
    background: #5e626f;
    color: white;

    .call {
      height: 42px;
      padding: 10px;
      background: #5cb8ff;
    }

    .chatBlock {
      background: azure;
      height: 500px;

      .receive {
        display: flex;
        height: 46px;
        align-items: center;

        &__message {
          width: 115px;
          font-size: 10px;
          background: #cccccc;
          height: fit-content;
          padding: 8px;
          border-radius: 10px 10px 10px 0;
        }
      }

      .sender {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        &__message {
          width: 115px;
          font-size: 10px;
          background: blueviolet;
          height: fit-content;
          padding: 8px;
          border-radius: 10px 10px 0 10px;
        }
      }
    }
  }


  .profileBlock,
  .paperMat {
    background: #1d262f;
    box-shadow: rgba(147, 218, 266, 0.2) 0 0 18px;
  }

  .paperMat {
    p {
      margin: 0;
      color: white;
    }

    div {
      color: white;

      input ~ svg {
        color: white;
      }
    }

    input {
      color: white;
    }

    th, td {
      color: white;
    }
  }

  .lineChart {
    width: 47%;
    color: white;
    height: 340px;
    border-radius: 40px;
    display: flex;
    box-shadow: rgba(80, 143, 244, 0.1) 0 12px 30px;
    align-items: center;
    padding: 11px;
    margin: 5px 5px;
  }
}


@import "media/media";
