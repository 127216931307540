//HOME
.logoImg {
  width: 35px;
  height: 35px;
}

.logoName {
  font-size: 23px;
}

.profileTitle,
.singleTitle,
.aboutCleaningMainTitle,
.aboutMainTitle,
.blockquoteTitle,
.newTitle {
  font-size: 29px;
  margin-bottom: 25px;
}

.singleMainInfo,
.aboutCleaningMainInfo,
.aboutMainInfo,
.blockquoteInfo,
.newHomeInfo {
  font-size: 17px;
  margin-bottom: 50px;
}


//banner
.bannerRow {
  flex-direction: column;
}


.bannerRow {
  padding: 40px 15px 30px 15px;
}

.banner2Figure {
  width: 300px;
  height: 300px;
  margin-bottom: 40px;
}


.bannerFigure {
  width: 300px;
  height: 300px;
  margin-bottom: 10px;
}

.banner2Img {
  border-radius: 50%;
  border: 10px solid #ffece5;
}

.banner2Desk,
.bannerDesk {
  width: 100%;
  padding: 10px;
  text-align: center;
}


.bannerTitle {
  font-size: 25px;
  margin-bottom: 10px;
}

.banner2Info,
.bannerInfo {
  font-size: 15px;
  margin-bottom: 15px;
}

.bannerDiscounts {
  font-size: 19px;
  margin-bottom: 15px;
}

.bannerDiscounts span {
  padding: 5px;
  color: #ffece5;
  font-size: 18px;
  font-weight: 500;
  border-radius: 50%;
  border: 5px solid #ffece5;
}

.bannerBtn {
  margin-bottom: 20px;
}

//assortment
.assortment {
  width: 100%;
  flex-direction: column;
}

.assortmentColumnGroup,
.assortmentColumn1 {
  width: 100%;
}

.assortmentColumn2,
.assortmentColumn3 {
  width: calc(100% / 2);
}

.assortmentBtn {
  width: 100%;
  height: 30px;
  padding: 20px;
}

//home about
.homeAbout {
  display: flex;
  flex-direction: column;
}

.homeAboutItem {
  width: 100%;
}

.homeAboutDesk {
  width: 100%;
  padding: 15px;
  background-color: #ffece5;
}

.homeAboutTitle {
  font-size: 25px;
  padding: 0 0 15px;
  text-transform: capitalize;
}

//quotes
.quotesBlock {
  padding: 35px;
}

.quoteSymbol {
  font-size: 45px;
  font-weight: 600;
  margin-bottom: -15px;
}

.quote {
  padding: 0;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 45px;
}

.thePerson {
  font-size: 15px;
  font-weight: 500;
}

.quoteLine {
  margin-top: -10px;
  width: calc(100% / 4);
  border: 1px solid #c31e39;
  background-color: #c31e39;
}


//ABOUT
.aboutCleaningBlock,
.aboutBlock {
  padding: 20px;
}

.aboutClean {
  width: 100%;
  padding: 20px;
}

.aboutCleaningTitle,
.aboutTitle {
  font-size: 24px;
  padding: 0 0 15px;
}

.aboutCleaningFigure,
.aboutFigure {
  flex-direction: column;
}

.aboutCleaningVideo,
.aboutCleaningImg,
.aboutCleaningInfo,
.aboutInfo,
.aboutImg {
  width: 100%;
  margin: 0 0 20px 0;
}


//SHOP
.shopPageSection {
  height: 500px;
}

.shopAside {
  padding: 70px 0 0;
}

.shopTitle {
  font-size: 40px;
}

.shopSlogan {
  font-size: 16px;
}

//SINGLE
.singlePage {
  padding: 20px;
  flex-direction: column;
}

.singleItem {
  width: 100%;
  height: 300px;
  margin: 0 0 20px 0;
}

.singleInfoQuantity {
  width: 80%;
  border: 0;
  margin: 0 auto 20px;
}

.singleInfoInput,
.singleBtnM,
.singleBtnP {
  border: 1px solid #4a4a4a;
  background-color: #ffece5;
}

//.singleInfoBtn,
//.singleInfo {
//  width: 100%;
//  position: static;
//}

.singleInfoTitle {
  font-size: 24px;
}

.singleInfoDescription,
.singleInfoPrice {
  font-size: 19px;
}

//LOGIN & REGISTER & CONTACT
.red {
  background-color: #ffece5;
}

.contact,
.contact .container,
.registrationPage,
.registrationPage .container,
.logIn .container,
.logIn {
  padding: 0;
  background-color: #fff;
}

.contactPage,
.regPage,
.loginPage {
  width: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.contactLogo {
  display: none;
}

.contactPage {
  flex-direction: column;
}

.contactMap {
  width: 100%;
  height: 300px;
}

.contactDesk {
  width: 100%;
}

.contactForm {
  padding: 0;
  margin: 0 auto
}

.contactBtn {
  margin-bottom: 40px;
}

.contactSoc {
  flex-direction: column;
}

.contactLink {
  color: #c31e39;
}

//SHOP
.shop .row {
  flex-direction: column;
}

.shopProduct,
.shopSection,
.shopAside {
  width: 100%;
}

//PROFILE
.customerInactive {
  color: #c31e39;
}

.customerWelcome {
  font-size: 17px;
}

//FOOTER
.footer {
  padding: 50px 15px;
}

.footerStartBlock,
.footerRow {
  flex-direction: column;
}

.footerBlock {
  width: 100%;
  margin-bottom: 35px;
}

.footerInfo {
  padding: 0;
}

.footerStartBlock,
.footerEndBlock {
  width: 100%;
}

.footerTitle {
  font-size: 20px;
  margin-bottom: 15px;
}

.footerSocDesk {
  font-size: 18px;
}

.footerSocDesk {
  padding: 0;
  margin-top: -10px;
}

.footerContactLink {
  font-size: 15px;
}

.cartTable.orderTable{
  display: block;
  overflow: auto;
}
