* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  display: flex;
}

.active {
  color: #8dd7f1;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  transition: all 0.4s;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  transition: all 0.4s;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  transition: all 0.4s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3e5c7d;
  transition: all 0.4s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gwDiyc {
  flex-direction: column;
}

/* Works for Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  color: #4a4a4a;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  background: #f6f7fb;
  overflow-x: hidden;
}
body .settings {
  padding: 40px;
  margin: 40px;
  background: white;
  min-height: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
}
body .dial {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
body .dial div {
  width: 22%;
  margin: 9px 12px;
  background: white;
  height: 56px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: all 0.4s;
}
body .dial div:hover {
  background: #ccc;
  cursor: pointer;
}
body .company_detail p {
  margin-bottom: 10px !important;
}
body .audioBlock {
  display: flex;
  margin-top: 51px;
  flex-direction: column;
}
body .audioBlock audio {
  margin-top: 20px;
}
body .audioBlock span {
  font-size: 20px;
}
body .callImgBlock {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
  margin-right: 40px;
}
body .callImgBlock img {
  width: 100%;
  border-radius: 100%;
  object-fit: cover;
}
body .call_action {
  padding: 4px 50px;
  border: 1px solid #3da4ff;
  margin-right: 10px;
  background: white;
}
body .call_btn {
  padding: 20px;
  border: none;
  background: #9aff9a;
  border-radius: 100%;
  margin-top: 30px;
  color: white;
}
body .callScreenOutBack {
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 0.53);
  top: 0;
  left: 0;
  height: 100vh;
  scroll-behavior: unset;
  bottom: 0;
  right: 0;
  z-index: 6000;
}
body .company_create {
  border: none;
  padding: 10px;
  color: white;
  background: #3da4ff;
}
body .react-datetime-picker__wrapper {
  align-items: center;
}
body .menuLink {
  height: 60px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-right: 1px solid #74c5e2;
}
body .menuLink a {
  color: #8cbbcd;
}
body .menuLink a.active {
  color: #74c5e2;
}
body .triangle {
  width: 0;
  height: 0;
  position: fixed;
  border-style: solid;
  border-width: 18px 13px 0 13px;
  border-color: #adc9ee transparent transparent transparent;
  bottom: 82px;
  right: 49px;
}
body .wrapper_call {
  position: fixed;
  bottom: 28px;
  right: 35px;
  width: 50px;
  height: 50px;
  background: #00ccff;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.4s;
}
body .wrapper_call:hover {
  background: #6e89c1;
}
body .verifyBlock {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7098039216);
  z-index: 700;
  top: 0;
  left: 0;
}
body .verifyBlock .verifyChild {
  padding: 20px;
  background: white;
  height: 240px;
  width: 475px;
  position: fixed;
  margin: auto;
  top: 0;
  display: flex;
  flex-direction: column;
  right: 0;
  align-items: center;
  bottom: 0;
  left: 0;
  justify-content: space-between;
}
body .verifyBlock .verifyChild div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body .verifyBlock .verifyChild span {
  cursor: pointer;
}
body .verifyBlock .verifyChild label {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .verifyBlock .verifyChild label input {
  border-bottom: 1px solid black;
  transition: all 0.4s;
}
body .verifyBlock .verifyChild label input:focus {
  border-bottom: 1px solid #6db0d5;
}
body .navProfile {
  width: 490px;
  height: 60px;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 80px;
  z-index: 6;
  display: flex;
  flex-direction: row;
  transition: all 0.4s;
}
body .navProfile a {
  color: #2a2a2a;
  width: 75px;
}
body .navProfile.show {
  left: -440px;
}
body .mainTask {
  padding: 23px;
  overflow: auto;
  display: flex;
  flex-direction: row;
}
body .mainTask > div {
  margin: 0 20px;
}
body .mainTask > div div {
  min-height: 50px;
}
body .mainTask div {
  width: 235px;
  text-align: center;
  border-bottom: 2px solid #cccccc;
  display: flex;
  flex-direction: column;
}
body .mainTask div .count {
  color: #cccccc;
}
body .mainTask .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  background: white;
  border: 1px solid #cccccc;
  padding: 0 10px;
  margin: 5px 0;
}
body .mainTask .today {
  border-bottom: 2px solid #74c5e2;
}
body .mainTask .old {
  border-bottom: 2px solid red;
}
body .mainTask span.old {
  color: #c31e39;
  border-bottom: none;
}
body .mainTask span.today {
  color: #74c5e2;
  border-bottom: none;
}
body .taskLineEdit {
  position: fixed;
  bottom: 0;
  width: inherit;
  background: white;
  height: 100px;
}
body .taskLineEdit ul {
  padding: 0 !important;
  list-style: none;
  display: flex;
  justify-content: center;
}
body .taskLineEdit ul li {
  padding: 37px 90px;
  border-top: 3px solid gray;
  margin: 0 5px;
}
body .taskLineEdit ul li.delete {
  padding: 37px 90px;
  border-top: 3px solid red;
}
body .taskTable {
  padding: 30px;
  overflow: auto;
}
body .taskTable .history_filter {
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  background: white;
  font-size: 15px;
}
body .taskTable table, body .taskTable th {
  border: 1px solid #cccccc;
  background: white;
}
body .taskTable table {
  width: 100%;
  border-collapse: collapse;
}
body .taskTable table tbody tr {
  transition: 0.4s;
}
body .taskTable table tbody tr:hover {
  background: #e8e8e8;
}
body .taskTable table .paginationBlock ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0 !important;
  justify-content: flex-end;
}
body .taskTable th, body .taskTable td {
  height: 40px;
  padding: 1px 19px;
  font-size: 11px;
  min-width: 130px;
}
body .taskTable .taskAction span {
  padding: 10px 20px;
  border-right: 1px solid #ccc;
  cursor: pointer;
}
body .taskTable .taskAction span span {
  margin: 0 5px;
  border-right: none;
}

.taskBtn {
  border: none;
  padding: 10px;
  margin: 0 25px;
  color: white;
  background: #74c5e2;
  width: 190px;
  transition: all 0.4s;
}
.taskBtn:hover {
  background: #3e7f95;
}
.taskBtn.disable {
  color: white;
  background: #cccccc;
}
.taskBtn.disable:hover {
  background: #cccccc;
}

.modalCreateAndUpdate span {
  color: #727272;
  font-size: 14px;
  border: none;
  width: 0;
  min-width: fit-content;
}
.modalCreateAndUpdate .react-datetime-picker {
  width: 100%;
}
.modalCreateAndUpdate label {
  margin: 10px 0;
}
.modalCreateAndUpdate label input {
  margin: 10px;
  width: 91%;
  border: none;
  outline: none;
  border-bottom: solid 1px black;
  transition: all 0.4s;
}
.modalCreateAndUpdate label select {
  outline: none;
  height: 40px;
  width: 86%;
  margin-left: 8px;
}
.modalCreateAndUpdate label textarea {
  width: 91%;
}
.modalCreateAndUpdate input::placeholder,
.modalCreateAndUpdate textarea::placeholder {
  font-size: 12px;
}
.modalCreateAndUpdate textarea {
  height: 28px;
}

.process_single {
  min-width: 120px;
}

.process_border {
  border-bottom: 1px solid;
  padding-bottom: 10px;
}

.content_header {
  height: 220px;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.content_header input {
  font-size: 19px;
  border-bottom: none;
}
.content_header textarea {
  font-size: 15px;
  border-bottom: none;
  background: transparent;
  width: 100%;
  resize: none;
}
.content_header p {
  margin: 10px !important;
  display: flex;
}
.content_header p select {
  border: none;
  background: transparent;
  outline: none;
}

.content_body {
  height: calc(100% - 220px);
  background: white;
  border-left: 2px solid #f6f7fb;
}

.taskFilter {
  display: flex;
  background: #f6f7fb;
  align-items: center;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
  justify-content: space-between;
}
.taskFilter .dayFilter {
  padding: 17px;
  width: 200px;
  display: flex;
  justify-content: space-around;
  border-right: 1px solid #74c5e2;
}
.taskFilter .dayFilter span {
  cursor: pointer;
  transition: all 0.4s;
}
.taskFilter .dayFilter span:hover {
  color: #cccccc;
}
.taskFilter select {
  height: 45px;
  margin-left: 20px;
  border: none;
  background: transparent;
  outline: none;
}

.submitBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-top: 29px;
}
.submitBtn span {
  padding: 10px;
  background: #80dbef;
  color: white;
  cursor: pointer;
  transition: all 0.4s;
}
.submitBtn span:hover {
  background: #639ab1;
}

#task {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.task {
  background: white;
  padding: 20px;
}

#task td, #task th {
  border: 1px solid #ddd;
  padding: 8px;
}

#task tr:nth-child(even) {
  background-color: #f2f2f2;
}

#task tr:hover {
  background-color: #ddd;
}

#task th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: white;
}

.callFromWrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(63, 63, 63, 0.6784313725);
}

.sideBarMenuUl .barMenuLi:hover,
.sideBarMenuUl .barMenuLi.active {
  z-index: 555555;
}

.loginContainer {
  position: absolute;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  background: white;
  height: 300px;
  bottom: 0;
  width: 483px;
  padding: 20px;
}
.loginContainer .adminLoginForm {
  height: 260px;
  position: relative;
}
.loginContainer .adminLoginPage {
  height: 260px;
}
.loginContainer .adminLoginHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.loginContainer .adminLoginMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.loginContainer #adminPass {
  text-align: center;
}
.loginContainer #adminPass::placeholder {
  text-align: center;
}
.loginContainer .adminInput input {
  border: none;
  outline: none;
  align-items: center;
  border-bottom: 1px solid;
  border-radius: 10px;
  padding: 0 10px;
  width: 245px;
  transition: all 0.4s;
  text-align: center;
}
.loginContainer .adminInput input:focus {
  border-bottom: 1px solid #c0e9f7;
}
.loginContainer .adminInput input::placeholder {
  text-align: center;
}
.loginContainer .adminFormLabel {
  align-items: center;
  border: none;
  border-bottom: 1px solid;
  border-radius: 10px;
  margin: 17px 0 40px;
  padding: 0 10px;
  width: 245px;
  transition: all 0.4s;
}
.loginContainer .adminFormLabel:focus-within {
  border-bottom: 1px solid #c0e9f7;
}
.loginContainer #adminPass {
  border: none;
  outline: none;
  width: 195px;
}
.loginContainer .adminLoginBtn {
  border: none;
  padding: 5px 30px;
  background: #97e7ff;
  color: white;
  font-size: 20px;
  border-radius: 4px;
  transition: all 0.4s;
}
.loginContainer .adminLoginBtn:hover {
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
  background: #4d98ac;
}

p {
  margin: 0;
}

.adminHeader {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.adminRow {
  min-width: calc(100% - 81px);
  width: 100%;
  height: fit-content;
  flex-direction: column;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.searchLabel {
  width: 40px;
  height: 40px;
  border: 1px solid;
  display: inline-flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}

.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
}

.input-search {
  height: 30px;
  width: 30px;
  border-style: none;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: #8dd7f1;
  color: transparent;
  padding: 10px;
}

.input-search::placeholder {
  color: transparent;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
  padding: 10px 0;
}

.btn-search {
  width: 30px;
  height: 30px;
  border-style: none;
  font-size: 15px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #ffffff;
  background-color: transparent;
  pointer-events: painted;
}

.btn_Yes,
.btn_no {
  width: 70px;
  border: none;
  color: white;
  background: red;
  border-radius: 4px;
  margin: 0 35px;
}

.btn_no {
  background: #8dd7f1;
}

.btn-search:focus ~ .input-search {
  width: 300px;
  border-radius: 0;
  background: #8dd7f1;
  border-bottom: 1px solid #8dd7f1;
  color: white;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

.input-search:focus {
  width: 300px;
  color: white;
  border-radius: 0;
  background: #8dd7f1;
  border-bottom: 1px solid #8dd7f1;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

.btn-search:focus ~ .input-search::placeholder {
  color: #fff;
}

.headerProfileImg {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.adminSideBar {
  min-height: 100vh;
  max-height: 999999px;
  height: auto;
  width: 80px;
  background: #fff;
  transition: all 0.4s;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.adminSideBar .nameBlock {
  display: flex;
  flex-direction: column;
  font-size: 15px;
}
.adminSideBar > .sideBarMenuUl .barMenuLi {
  width: 100%;
  height: 65px;
}

.logo {
  color: #ccc;
}

.text {
  margin-top: 10px;
  transition: all 0.4s;
}

.menuBarIcon {
  right: 28px;
}

.sideBarMenuUl {
  list-style: none;
  color: rgba(150, 205, 212, 0.831372549);
  padding: 0;
}
.sideBarMenuUl a {
  text-decoration: none;
  color: rgba(1, 144, 168, 0.83);
}
.sideBarMenuUl a span {
  font-size: 10px;
}
.sideBarMenuUl .barMenuLi {
  display: flex;
  flex-direction: column;
  margin: 0;
  align-items: center;
  width: 100%;
  transition: all 0.4s;
  padding: 5px 10px;
}
.sideBarMenuUl .barMenuLi:hover, .sideBarMenuUl .barMenuLi.active {
  color: rgba(158, 238, 255, 0.83);
  border-radius: 10px;
}
.sideBarMenuUl .barMenuLi:hover span, .sideBarMenuUl .barMenuLi.active span {
  color: rgba(158, 238, 255, 0.83);
}
.sideBarMenuUl .barMenuLi .custom_tooltip {
  height: 45px;
  padding: 10px 0;
  width: calc(100% - 80px);
  background: transparent;
  position: absolute;
  left: 80px;
  display: flex;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
  border-radius: 10px;
}
.sideBarMenuUl .barMenuLi .custom_tooltip a {
  pointer-events: none;
}
.sideBarMenuUl .barMenuLi a {
  margin-left: 10px;
  text-decoration: none;
  color: #8dd7f1;
  transition: all 0.4s;
  pointer-events: none;
}
.sideBarMenuUl .barMenuLi a:hover ~ span {
  background: white;
  color: black;
  border-radius: 10px;
}

.adminSideBar.show ~ .adminRow {
  min-width: calc(100% - 335px);
}
.adminSideBar.show .sideBarMenuUl .barMenuLi {
  width: 100%;
  transition: all 0.4s;
  padding: 10px;
}
.adminSideBar.show .sideBarMenuUl .barMenuLi:hover, .adminSideBar.show .sideBarMenuUl .barMenuLi.active {
  background: #f6f7fb;
  color: rgba(150, 205, 212, 0.831372549);
  border-radius: 10px;
}
.adminSideBar.show .sideBarMenuUl .barMenuLi:hover span, .adminSideBar.show .sideBarMenuUl .barMenuLi.active span {
  color: rgba(150, 205, 212, 0.831372549);
}
.adminSideBar.show .sideBarMenuUl .barMenuLi:hover .content, .adminSideBar.show .sideBarMenuUl .barMenuLi.active .content {
  display: block;
}
.adminSideBar.show .sideBarMenuUl .barMenuLi .custom_tooltip {
  display: none;
}
.adminSideBar.show .sideBarMenuUl .barMenuLi a {
  margin-left: 10px;
  text-decoration: none;
  color: #8dd7f1;
  transition: all 0.4s;
  pointer-events: auto;
}

.dWLNfR {
  flex-direction: column;
}

.sc-dkrFOg.dmajdp {
  flex-direction: column;
}

.logo.textShow ~ .menuBarIcon {
  right: 27px;
}

.show {
  width: 335px;
}

.textShow {
  opacity: 0;
}

.profilePopUp {
  list-style: none;
  width: 100%;
  padding: 0;
  font-size: 18px;
  margin: 0;
}
.profilePopUp .popLi {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  /* margin: 0 28px; */
  padding: 12px;
  text-transform: capitalize;
  transition: all 3.4s;
}
.profilePopUp .popLi a {
  color: black;
  margin-left: 10px;
  text-decoration: none;
  transition: all 0.4s;
}
.profilePopUp .popLi .popLiDiv {
  display: flex;
  flex-direction: column;
  font-size: 11px;
}
.profilePopUp .popLi .message {
  color: #cccccc;
}
.profilePopUp .popLi:hover a {
  color: white;
}
.profilePopUp .popLi span {
  margin-left: 10px;
}

.content {
  background: #f6f7fb;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.showAcc {
  height: auto;
  max-height: 99999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.settings__list {
  width: 100%;
  margin-top: 30px;
}
.settings__list select {
  height: 40px;
  margin: 12px 33px;
  width: 237px;
  border: 1px solid #616161;
}
.settings__list .setting__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #cdf3ff;
  border-radius: 100%;
}

.css-advny6-MuiPopper-root {
  top: 20px !important;
  left: -53px !important;
  height: 600px;
  overflow: hidden !important;
  overflow-y: auto !important;
}

.language__down {
  left: 0 !important;
  z-index: 2147483647;
}

.profile__down {
  left: -15px !important;
}

.dialScreen,
.callScreen {
  height: 400px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.dialScreen input,
.callScreen input {
  background: transparent;
}
.dialScreen .dial div,
.callScreen .dial div {
  width: 20%;
  height: 40px;
}
.dialScreen .call_btn,
.callScreen .call_btn {
  padding: 10px;
}
.dialScreen .PhoneInputCountrySelect,
.callScreen .PhoneInputCountrySelect {
  display: none;
}
.dialScreen .call-animation,
.callScreen .call-animation {
  background: #fff;
  width: 135px;
  height: 135px;
  position: relative;
  margin: 0 auto;
  border-radius: 100%;
  border: solid 5px #fff;
  animation: play 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.dialScreen .img,
.callScreen .img {
  width: 135px;
  height: 135px;
  border-radius: 100%;
  position: absolute;
  left: -4px;
  top: -4px;
}
@keyframes play {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 5px rgba(32, 69, 134, 0.4);
  }
  25% {
    box-shadow: 0 0 0 10px rgba(54, 141, 199, 0.21), 0 0 0 20px rgba(87, 187, 227, 0.2);
  }
  25% {
    box-shadow: 0 0 0 15px rgba(92, 144, 255, 0.4), 0 0 0 30px rgba(71, 160, 238, 0.27);
  }
}
.dialScreen .callBTN,
.callScreen .callBTN {
  display: inline-block;
  background: #22d522;
  color: white;
  padding: 10px;
  border-radius: 45px;
  cursor: pointer;
  transition: all 0.4s;
}
.dialScreen .callBTN:hover,
.callScreen .callBTN:hover {
  background: green;
}
.dialScreen .callBTN.red,
.callScreen .callBTN.red {
  background: red;
}
.dialScreen .callBTN.red:hover,
.callScreen .callBTN.red:hover {
  background: #c31e39;
}
.dialScreen div div .phone_input,
.callScreen div div .phone_input {
  width: 200px;
}
.dialScreen div div .phone_input input,
.callScreen div div .phone_input input {
  border: none;
  text-align: center;
  background: transparent;
}

.dialScreen {
  height: 400px;
  position: fixed;
  right: 24px;
  bottom: -46px;
}

.callScreen {
  top: 0;
  left: 0;
  margin: auto;
  width: 300px;
  background: white;
}

.dropTheme {
  background: #fff;
  color: #4a4a4a;
}
.dropTheme .callHeader {
  height: 42px;
  padding: 10px;
  background: #5cb8ff;
  color: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.dropTheme .history_input {
  border-bottom: 1px solid #cccccc;
  transition: all 0.4s;
  width: 88%;
  margin-bottom: 40px;
}
.dropTheme .history_input:focus {
  border-bottom: 1px solid #80d6ef;
}
.dropTheme .startAndEnd {
  border-bottom: 1px solid #cccccc;
  transition: all 0.4s;
  width: 88%;
  margin-bottom: 40px;
}
.dropTheme .startAndEnd:focus {
  border-bottom: 1px solid #80d6ef;
}
.dropTheme .history_icon:hover {
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.dropTheme .history_select {
  height: 40px;
  outline: none;
  margin-left: 10px;
  width: 88%;
}
.dropTheme .historyList {
  height: 48px;
  display: flex;
  padding: 3px;
  border-bottom: 1px solid #ccc;
}
.dropTheme .historyList .call_icon {
  width: 40px;
  height: 40px;
  border: 1px solid;
  border-radius: 100%;
  display: inline-flex;
  margin: 0;
  justify-content: center;
  align-items: center;
}
.dropTheme .historyList .call_info {
  display: inline-flex;
  flex-direction: column;
  margin: 0 20px;
}
.dropTheme .callNumberBlock {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  justify-content: flex-end;
}
.dropTheme .callNumberBlock .numberItem {
  cursor: pointer;
  margin: 15px 26px;
  background: #ddf1f7;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  transition: all 0.4s;
}
.dropTheme .callNumberBlock .numberItem:hover {
  background: #89a4af;
}
.dropTheme .notification {
  display: flex;
  height: 40px;
  align-items: center;
  padding: 20px;
  background: blueviolet;
  border-radius: 5px 5px 0 0;
}

.icon__count {
  top: -11px;
  right: -3px;
  padding: 3px;
  background: #8dd7f1;
  border-radius: 10px;
  font-size: 10px;
}

.adminMain {
  width: 100%;
  padding: 25px;
}
.adminMain .back {
  background: rgba(0, 0, 0, 0.4509803922);
  width: 100%;
  height: 100vh;
  z-index: 5;
}
.adminMain input {
  transition: all 0.4s;
  background: transparent;
}
.adminMain .profileBlock {
  margin: 0;
  width: 100%;
  padding: 40px;
  background: white;
  box-shadow: 0 12px 30px rgba(80, 143, 244, 0.1);
}
.adminMain .profileBlock .color {
  color: black;
}
.adminMain .profileBlock .profileLeft .profilePictureBlock {
  margin: 0;
  height: 225px;
  width: 225px;
  background: aliceblue;
  position: relative;
}
.adminMain .profileBlock .profileLeft .profilePictureBlock label {
  height: 100%;
  min-width: 100%;
  margin: 0;
}
.adminMain .profileBlock .profileLeft .profilePictureBlock .blockImg {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  object-position: center;
  top: 0;
}
.adminMain .profileBlock .profileLeft .profilePictureBlock .close_add {
  position: absolute;
  background: blue;
  width: 25px;
  height: 25px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  top: -10px;
  right: -11px;
  z-index: 12;
  cursor: pointer;
}
.adminMain .profileBlock .profileLeft input {
  border: none;
  outline: none;
  color: #b0b0b0;
  transition: all 0.4s;
}
.adminMain .profileBlock .profileLeft .city_country {
  font-size: 22px;
  font-weight: 600;
  color: #435564;
}
.adminMain .profileBlock .profileLeft .address {
  margin: 10px 0;
  color: #b0b0b0;
}
.adminMain .profileBlock .profileLeft .address.color > input {
  color: black;
}
.adminMain .profileBlock .profileLeft .role {
  display: flex;
  flex-direction: column;
}
.adminMain .profileBlock .profileRight .editBlock {
  color: rgb(119, 119, 119);
  margin-left: 10px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  min-width: 145px;
  overflow-wrap: break-word;
  word-break: break-all;
}
.adminMain .profileBlock .profileRight .edit {
  padding: 0 10px;
  margin-left: 10px;
  outline: none;
  border: none;
  height: fit-content;
  color: white;
  background: #317eff;
}
.adminMain .profileBlock .profileRight .name_loc {
  display: flex;
}
.adminMain .profileBlock .profileRight .name_loc.color input {
  color: black;
}
.adminMain .profileBlock .profileRight .name_loc input {
  min-width: 100px;
  width: 160px;
  margin: 0 5px;
}
.adminMain .profileBlock .profileRight .role {
  display: inline-block;
  margin: 10px 0;
  color: #5bbeda;
}
.adminMain .profileBlock .profileRight .contact {
  min-width: 200px;
  display: inline-block;
  width: fit-content;
}
.adminMain .profileBlock .profileRight .info > div {
  margin: 20px 0;
}
.adminMain .profileBlock .profileRight .info > div.color input {
  color: black;
}
.adminMain .profileBlock input {
  border: none;
  outline: none;
  color: #b0b0b0;
}
.adminMain .profileBlock .headline {
  margin: 21px 0;
  color: #bbbbbb;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.adminMain .profileBlock .headline .line {
  height: 2px;
  width: 100%;
  background: #efefef;
  margin: 0 10px;
}

.callBlock,
.lineChart {
  color: white;
  height: 340px;
  border-radius: 40px;
  display: flex;
  box-shadow: rgba(80, 143, 244, 0.1) 0 12px 30px;
  align-items: center;
  justify-content: center;
  padding: 11px;
  margin: 5px 5px;
  width: 47%;
}

.descLabel {
  margin: 25px 10px;
}

.headerBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.callBlockTime {
  background: white;
  height: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  box-shadow: rgba(80, 143, 244, 0.1) 0 12px 30px;
  margin: 5px 5px;
  width: 47%;
}
.callBlockTime .button {
  padding: 4px 30px;
  border: none;
  color: white;
  box-shadow: rgba(80, 143, 244, 0.1) 0 12px 30px;
}

.deleteTitle {
  font-size: 26px;
  color: #8dd7f1;
  display: flex;
  justify-content: center;
}

.deleteBtnBlock {
  display: flex;
  justify-content: center;
  height: 40px;
  margin: 24px;
}

.search input {
  margin: 10px;
  border: none;
  outline: none;
  border-bottom: solid 1px black;
  transition: all 0.4s;
}
.search input:focus {
  border-color: #8dd7f1;
}

div div label {
  margin: 10px;
}
div div input, div div textarea {
  border: none;
  outline: none;
  border-bottom: solid 1px black;
  transition: all 0.4s;
}
div div input.taskInput, div div textarea.taskInput {
  width: 293px;
  margin: 10px 0;
}
div div input:focus, div div textarea:focus {
  border-color: #8dd7f1;
}
div label {
  margin: 10px 0;
}
div label span {
  margin: 0 10px;
  color: black;
  min-width: 145px;
  border-bottom: 1px solid #35cfed;
}
div label input {
  outline: none;
  width: 100%;
}
div label input::placeholder {
  color: #848484;
}

.createHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 13px 0;
}

.imageLabel {
  background: #35cfed;
  padding: 10px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: 0.4s;
}
.imageLabel:hover {
  box-shadow: rgba(59, 113, 210, 0.25) 0 12px 30px;
}

.dragBlockDiv {
  height: 500px;
  cursor: grab;
  box-shadow: rgba(59, 113, 210, 0.25) 0 12px 30px;
  width: 33%;
  min-width: 200px;
  background: white;
  border-radius: 10px;
}

.loaderBack {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 253, 253, 0.7490196078);
  top: 0;
  left: 0;
  z-index: 54554;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createBtn {
  border: none;
  padding: 3px 32px;
  margin: 10px;
  text-align: center;
  color: white;
  background: #35cfed;
  border-radius: 5px;
  transition: all 0.4s;
  cursor: pointer;
}
.createBtn:hover {
  background: #146d80;
  box-shadow: rgba(59, 113, 210, 0.25) 0 12px 30px;
}

body.dark {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
  background: #2A3F54;
}
body.dark ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  transition: all 0.4s;
}
body.dark ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  transition: all 0.4s;
}
body.dark ::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  transition: all 0.4s;
}
body.dark ::-webkit-scrollbar-thumb:hover {
  background: #3e5c7d;
  transition: all 0.4s;
}
body.dark .callBlockTime {
  background: #2A3F54;
}
body.dark .callBlockTime div {
  color: white;
}
body.dark .callBlockTime div div {
  font-size: 30px;
}
body.dark .adminRow {
  color: white;
}
body.dark .adminSideBar {
  background: #000;
  min-height: 100vh;
  max-height: 999999px;
  height: auto;
}
body.dark .adminSideBar .sideBarMenuUl .barMenuLi:hover,
body.dark .adminSideBar .sideBarMenuUl .barMenuLi.active {
  background: #5e626f;
  width: 215px;
  color: rgba(150, 205, 212, 0.831372549);
  border-radius: 10px;
  z-index: 55555;
}
body.dark .adminSideBar .sideBarMenuUl .barMenuLi:hover .custom_tooltip,
body.dark .adminSideBar .sideBarMenuUl .barMenuLi.active .custom_tooltip {
  background: #5e626f;
}
body.dark .adminSideBar .sideBarMenuUl .barMenuLi:hover .content,
body.dark .adminSideBar .sideBarMenuUl .barMenuLi.active .content {
  background: #5e626f;
}
body.dark .popLi {
  color: white;
}
body.dark .popLi a {
  color: white;
  text-decoration: none;
}
body.dark .dropTheme {
  background: #5e626f;
  color: white;
}
body.dark .dropTheme .call {
  height: 42px;
  padding: 10px;
  background: #5cb8ff;
}
body.dark .dropTheme .chatBlock {
  background: azure;
  height: 500px;
}
body.dark .dropTheme .chatBlock .receive {
  display: flex;
  height: 46px;
  align-items: center;
}
body.dark .dropTheme .chatBlock .receive__message {
  width: 115px;
  font-size: 10px;
  background: #cccccc;
  height: fit-content;
  padding: 8px;
  border-radius: 10px 10px 10px 0;
}
body.dark .dropTheme .chatBlock .sender {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
body.dark .dropTheme .chatBlock .sender__message {
  width: 115px;
  font-size: 10px;
  background: blueviolet;
  height: fit-content;
  padding: 8px;
  border-radius: 10px 10px 0 10px;
}
body.dark .profileBlock,
body.dark .paperMat {
  background: #1d262f;
  box-shadow: rgba(147, 218, 255, 0.2) 0 0 18px;
}
body.dark .paperMat p {
  margin: 0;
  color: white;
}
body.dark .paperMat div {
  color: white;
}
body.dark .paperMat div input ~ svg {
  color: white;
}
body.dark .paperMat input {
  color: white;
}
body.dark .paperMat th, body.dark .paperMat td {
  color: white;
}
body.dark .lineChart {
  width: 47%;
  color: white;
  height: 340px;
  border-radius: 40px;
  display: flex;
  box-shadow: rgba(80, 143, 244, 0.1) 0 12px 30px;
  align-items: center;
  padding: 11px;
  margin: 5px 5px;
}

@media only screen and (max-width: 560px) {
  main {
    overflow: auto;
  }
  .callerMain {
    overflow: hidden;
  }
  * {
    font-size: 10px;
  }
  .taskBtn {
    margin: 0 15px;
  }
  .input__section {
    margin-bottom: 10px;
  }
  #phone__input {
    width: 70%;
  }
  header div label input {
    width: 100px;
  }
  #date_start__input, #date_end__input {
    width: 347px;
  }
  #calls, #music_hold, #state, #receive {
    width: 348px;
  }
  .media_for_this_block {
    margin-top: 90px;
  }
  .sipAndNerBlock {
    margin-top: 70px;
    flex-direction: column;
  }
  .sipAndNerLittleBlock.moreLittle {
    width: 100%;
  }
  .sipAndNerLittleBlock {
    width: 100%;
    justify-content: space-between;
  }
  .sipAndNerLittleBlock select {
    width: 348px;
  }
}
@media only screen and (max-width: 375px) {
  .logoImg {
    width: 35px;
    height: 35px;
  }
  .logoName {
    font-size: 23px;
  }
  .profileTitle,
  .singleTitle,
  .aboutCleaningMainTitle,
  .aboutMainTitle,
  .blockquoteTitle,
  .newTitle {
    font-size: 29px;
    margin-bottom: 25px;
  }
  .singleMainInfo,
  .aboutCleaningMainInfo,
  .aboutMainInfo,
  .blockquoteInfo,
  .newHomeInfo {
    font-size: 17px;
    margin-bottom: 50px;
  }
  .bannerRow {
    flex-direction: column;
  }
  .bannerRow {
    padding: 40px 15px 30px 15px;
  }
  .banner2Figure {
    width: 300px;
    height: 300px;
    margin-bottom: 40px;
  }
  .bannerFigure {
    width: 300px;
    height: 300px;
    margin-bottom: 10px;
  }
  .banner2Img {
    border-radius: 50%;
    border: 10px solid #ffece5;
  }
  .banner2Desk,
  .bannerDesk {
    width: 100%;
    padding: 10px;
    text-align: center;
  }
  .bannerTitle {
    font-size: 25px;
    margin-bottom: 10px;
  }
  .banner2Info,
  .bannerInfo {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .bannerDiscounts {
    font-size: 19px;
    margin-bottom: 15px;
  }
  .bannerDiscounts span {
    padding: 5px;
    color: #ffece5;
    font-size: 18px;
    font-weight: 500;
    border-radius: 50%;
    border: 5px solid #ffece5;
  }
  .bannerBtn {
    margin-bottom: 20px;
  }
  .assortment {
    width: 100%;
    flex-direction: column;
  }
  .assortmentColumnGroup,
  .assortmentColumn1 {
    width: 100%;
  }
  .assortmentColumn2,
  .assortmentColumn3 {
    width: 50%;
  }
  .assortmentBtn {
    width: 100%;
    height: 30px;
    padding: 20px;
  }
  .homeAbout {
    display: flex;
    flex-direction: column;
  }
  .homeAboutItem {
    width: 100%;
  }
  .homeAboutDesk {
    width: 100%;
    padding: 15px;
    background-color: #ffece5;
  }
  .homeAboutTitle {
    font-size: 25px;
    padding: 0 0 15px;
    text-transform: capitalize;
  }
  .quotesBlock {
    padding: 35px;
  }
  .quoteSymbol {
    font-size: 45px;
    font-weight: 600;
    margin-bottom: -15px;
  }
  .quote {
    padding: 0;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 45px;
  }
  .thePerson {
    font-size: 15px;
    font-weight: 500;
  }
  .quoteLine {
    margin-top: -10px;
    width: 25%;
    border: 1px solid #c31e39;
    background-color: #c31e39;
  }
  .aboutCleaningBlock,
  .aboutBlock {
    padding: 20px;
  }
  .aboutClean {
    width: 100%;
    padding: 20px;
  }
  .aboutCleaningTitle,
  .aboutTitle {
    font-size: 24px;
    padding: 0 0 15px;
  }
  .aboutCleaningFigure,
  .aboutFigure {
    flex-direction: column;
  }
  .aboutCleaningVideo,
  .aboutCleaningImg,
  .aboutCleaningInfo,
  .aboutInfo,
  .aboutImg {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .shopPageSection {
    height: 500px;
  }
  .shopAside {
    padding: 70px 0 0;
  }
  .shopTitle {
    font-size: 40px;
  }
  .shopSlogan {
    font-size: 16px;
  }
  .singlePage {
    padding: 20px;
    flex-direction: column;
  }
  .singleItem {
    width: 100%;
    height: 300px;
    margin: 0 0 20px 0;
  }
  .singleInfoQuantity {
    width: 80%;
    border: 0;
    margin: 0 auto 20px;
  }
  .singleInfoInput,
  .singleBtnM,
  .singleBtnP {
    border: 1px solid #4a4a4a;
    background-color: #ffece5;
  }
  .singleInfoTitle {
    font-size: 24px;
  }
  .singleInfoDescription,
  .singleInfoPrice {
    font-size: 19px;
  }
  .red {
    background-color: #ffece5;
  }
  .contact,
  .contact .container,
  .registrationPage,
  .registrationPage .container,
  .logIn .container,
  .logIn {
    padding: 0;
    background-color: #fff;
  }
  .contactPage,
  .regPage,
  .loginPage {
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  .contactLogo {
    display: none;
  }
  .contactPage {
    flex-direction: column;
  }
  .contactMap {
    width: 100%;
    height: 300px;
  }
  .contactDesk {
    width: 100%;
  }
  .contactForm {
    padding: 0;
    margin: 0 auto;
  }
  .contactBtn {
    margin-bottom: 40px;
  }
  .contactSoc {
    flex-direction: column;
  }
  .contactLink {
    color: #c31e39;
  }
  .shop .row {
    flex-direction: column;
  }
  .shopProduct,
  .shopSection,
  .shopAside {
    width: 100%;
  }
  .customerInactive {
    color: #c31e39;
  }
  .customerWelcome {
    font-size: 17px;
  }
  .footer {
    padding: 50px 15px;
  }
  .footerStartBlock,
  .footerRow {
    flex-direction: column;
  }
  .footerBlock {
    width: 100%;
    margin-bottom: 35px;
  }
  .footerInfo {
    padding: 0;
  }
  .footerStartBlock,
  .footerEndBlock {
    width: 100%;
  }
  .footerTitle {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .footerSocDesk {
    font-size: 18px;
  }
  .footerSocDesk {
    padding: 0;
    margin-top: -10px;
  }
  .footerContactLink {
    font-size: 15px;
  }
  .cartTable.orderTable {
    display: block;
    overflow: auto;
  }
}

