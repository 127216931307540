main{
  overflow: auto;
}

.callerMain{
  overflow: hidden;

}
*{
  font-size: 10px;
}
.taskBtn{
  margin: 0 15px;
}

.input__section {
  margin-bottom: 10px;
}

#phone__input {
  width: 70%;
}
header{
  div{
    label{
      input{
        width: 100px;
      }
    }
  }
}
#date_start__input, #date_end__input {
  width: 347px;
}
#calls, #music_hold, #state, #receive {
  width: 348px;
}

.media_for_this_block {
  margin-top: 90px;
}

.sipAndNerBlock {
  margin-top: 70px;
  flex-direction: column;
}

.sipAndNerLittleBlock.moreLittle {
  width: 100%;
}
.sipAndNerLittleBlock {
  width: 100%;
  justify-content: space-between;
}

.sipAndNerLittleBlock select {
  width: 348px;
}
