@media (pointer: coarse) {
  @import "pointer";
}

@media only screen and (max-width: 1170px) {
  @import "mx1170";
}

@media only screen and (max-width: 992px) {
  @import "mx992";
}

@media only screen and (max-width: 768px) {
  @import "mx768";
}

@media only screen and (max-width: 560px) {
  @import "mx560";
}

@media only screen and (max-width: 375px) {
  @import "mx375";
}
